import React, { useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { useAuth } from 'utils';
import hasAuthToken from 'lib/hasAuthToken';
import Layout from 'components/Layout';
import Card from 'components/Card';

const LoginPage = () => {
  const { t } = useTranslation();
  const { login } = useAuth();

  useEffect(() => {
    // Automatic signin
    login({ callbackPath: '/' });
  }, []);

  return (
    <Layout title={t('login.pageTitle')}>
      <div className="o-retain o-retain--lap u-margin-top-huge u-margin-bottom-huge">
        <Card>
          <h1>{t('login.pageHeading')}</h1>
          <p>{t('login.description')}</p>
        </Card>
      </div>
    </Layout>
  );
};

export async function getServerSideProps({ req, res, locale }) {
  const isAuthenticated = hasAuthToken(req);

  if (isAuthenticated) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  res.setHeader(
    'Cache-Control',
    'public, max-age=300, s-maxage=300, stale-while-revalidate=600'
  );

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default LoginPage;
